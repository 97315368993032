<!-- eslint-disable -->
<template>
  <!-- Content Section Start -->
  <div class="mt-6">
    <!-- Page title Section Start -->
    <div id="page-title" class="page-title-block" :style="style">
      <div class="container">
        <h1 class="post-title">Reffer a friend</h1>
      </div>
    </div>
    <!-- Page title Section End -->
    <!-- Benefits Start -->
    <div id="page-content">
      <div class="container">
        <div class="row">
          <div class="col-md-3 sidebar">
            <div class="widget-area">
              <aside id="categories-1" class="widget widget_categories">
                <h4 class="widget-title">Profile</h4>
                <ul>
                  <li
                    v-for="link in navLinks"
                    :key="link.id"
                  >
                    <router-link
                      :to="link.to"
                    >
                      {{ $t(link.label) }}
                    </router-link>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
          <div class="col-md-9 order-first order-md-last">
            <article class="post">
              <div class="container">
                <div class="contact-section">
                  <div class="col-md-12 text-center">
                    <div class="sec-title text-center">
                      <h3>Share your referral code</h3>
                    </div>
                    <div class="row">
                      <div class="col-md-12 d-flex align-items-center flex-column">
                        <qr-code :value="`https://elroscrypto.com?referral_code=${user.id}`" class="d-flex" size="350" level="H" />
                        <div class="form-row mt-4">
                          <div class="form-group">
                            <div class="d-flex align-items-center">
                              <input
                                id="code"
                                v-model="user.id"
                                type="text"
                                class="form-control"
                                name="code"
                                disabled
                              >
                              <span class="ml-4 copy-btn" @click.prevent="onCopy">Copy</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1" />
                </div>
              </div>
              <!-- Hero Section End -->
            </article>
          </div>
        </div>
      </div>
    </div>
    <!-- Benefits End -->
  </div>
</template>

<script>
  /* eslint-disable */
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { copyToClipboard } from '@/utils/copy-to-clipboard';
  import { nanoid } from 'nanoid';
  import { PROFILE_LINKS } from '@/constants/navbar-links';
  import backgroundBanner from '@/assets/jpg/profile.jpg';

  export default Vue.extend({
    name: 'elros-profile',
    data: () => ({
      style: { backgroundImage: `url(${backgroundBanner})` },
      user: {
        username: '',
        email: '',
        phoneNumber: '',
        walletAddress: '',
        referral_id: '',
      },
    }),
    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
      }),
      navLinks() {
        const isAdmin = this.getUser.role === 'admin';

        if (isAdmin) {
          const hasAdmin = PROFILE_LINKS.find(({ label }) => label === 'Admin');

          if (!hasAdmin) {
            PROFILE_LINKS.push({ label: 'Admin', to: '/admin', needsAuth: true });
          }
        }

        return PROFILE_LINKS
          .filter(item => item.needsAuth)
          .map(item => ({ ...item, id: nanoid() }));
      },
    },
    created() {
      const { query } = this.$router.currentRoute;

      if (query && query.confirmation_token) {
        this.confirmUser(query.confirmation_token);
      }
      if (this.getUser.id) {
        this.user = { ...this.getUser };
      }

      if (this.getUser.wallets?.length) {
        this.user.walletAddress = this.getUser.wallets[0]['wallet_address'];
      }

      if (this.getUser['phone_number']) {
        this.user.phoneNumber = this.getUser['phone_number'];
      }
    },
    methods: {
      ...mapActions({
        confirmUser: 'session/confirmUser',
        updateUser: 'session/updateUser',
      }),
      onCopy() {
        const fullLink = `https://elroscrypto.com?referral_code=${this.user.id}`;
        copyToClipboard(fullLink);
        this.$toasts.toast({
          id: nanoid(),
          message: 'Referral link copied',
          title: 'Success',
          intent: 'success',
        });
      },
      onSave() {
        let data = {
          ...this.user,
          type: 'users',
        };

        if (this.user.walletAddress) {
          data = {
            ...data,
            wallets: [{
              /* eslint-disable */
              wallet_address: this.user.walletAddress,
            }],
          };
        };

        delete data.relationshipNames;
        delete data.wallets;

        this.updateUser({
          credentials: { ...data },
        });
      },
    },
  });
</script>
<style>
  .btn-save,
  .copy-btn {
    background: #eec05e !important;
    background-color: #eec05e !important;
    border-radius: 4px;
    color: #1d1d1d;
    cursor: pointer;
    font-weight: 700;
    padding: 14px 30px;
    text-transform: uppercase;
  }

  .copy-btn {
    font-weight: 500;
    padding: 8px 16px;
  }
</style>
